import { createApp } from 'vue'
import App from './App.vue'// i18n部分的配置
import { createI18n } from "vue-i18n";
import router from "./router";
import './assets/css/custom.css'


// 引入语言包，注意路径
import Chinese from "./assets/js/i18n/zh.js";
import English from "./assets/js/i18n/en.js";
import TraditionalChinese from "./assets/js/i18n/tc.js";
import utils from './utils/utils'

// VueI18n
const i18n = createI18n({
    // 默认语言
    locale: "zh",
    // 引入语言文件
    messages: {
        zh: Chinese,
        en: English,
        tc: TraditionalChinese,
    },
});
export default i18n;
const app = createApp(App)
app.use(router);
app.use(i18n)
app.use(utils)
app.mount('#app')

const English = {
    company_name: 'CECEP Environmental Consulting Group (CECEPEC)',
    cookie: 'Cookie Policy',
    cookie_content: 'The aim of this Cookies Policy is to inform visitors of our Website about the nature, function and use of cookies, and to assist you in your choice of computer settings. By visiting our Website or using our Services, you accept and consent to the practices set out below. For more about how we collect and use your personal information and how you can exercise your statutory data subject rights please refer to our Privacy Policy.',
    privacy: 'Privacy Policy',
    privacy_content:'CECEP Environmental Consulting Group Limited and its affiliates (“CECEP” or “we”) respect your right to privacy and protect your personal information. This Privacy Policy explains how we collect, share and use your personal information, and how you can exercise your data subject rights, when you visit and use our website (“our Website”). By visiting our Website or using our services or any other applications or software we provide from time to time (collectively “our Services”), you accept and consent to the practices set out below.',
    cancel: 'Cancel',
    ensure: 'OK',
    language: 'Language',
    subscription: 'Subscription',
    last_update: 'Last updated: 03/03/2023',
    show_privacy: 'Show Detail',
    show_cookie: 'Show Detail',
    wechat_name: 'Official Accounts',
    wechat_company_name: '中节能皓信CECEPEC',
    agree_cookie: 'Accept Cookies',
    disagree_cookie: 'Don\'t Track'
}
export default English

const TraditionalChinese = {
    company_name: '中國節能皓信環境顧問集團有限公司',
    cookie: 'Cookie政策',
    cookie_content: '本《Cookie政策》旨在向我們網站的訪問者介紹Cookie的性質、功能和使用，並協助您選擇電腦設置。通過流覽我們的網站或使用我們不時提供的服務，您接受及同意下述做法。有關我們如何收集和使用您的個人資訊以及您可以如何行使您的法定資料主體權利的更多資訊，請參閱我們的《隱私政策》。',
    privacy: '隱私政策',
    privacy_content:'中國節能皓信環境顧問集團有限公司及其關聯方（“中國節能皓信”或“我們”）尊重閣下的隱私權並保護閣下的個人資訊。本隱私政策將說明當閣下訪問和使用我們的網站（待補充）（“我們的網站”）時，我們如何收集、共用和使用閣下的個人資訊，以及閣下可以如何行使閣下的資料主體權利。通過流覽我們的網站或使用我們的平臺及不時提供的服務或其它應用、軟體等（合稱“我們的服務”），閣下即表示接受及同意下述規則。',
    cancel: '取消',
    ensure: '確定',
    language: '語言',
    subscription: '訂閱',
    last_update: '最後更新日期：2023年3月3日',
    show_privacy: '查看完整隱私政策',
    show_cookie: '查看完整Cookie政策',
    wechat_name: '公眾號名稱',
    wechat_company_name: '中節能皓信CECEPEC',
    agree_cookie: '接受所有Cookies',
    disagree_cookie: '請勿追蹤'


}
export default TraditionalChinese

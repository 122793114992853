const Chinese = {
    company_name: '中国节能皓信环境顾问集团有限公司',
    cookie: 'Cookie政策',
    cookie_content: '本《Cookie政策》旨在向我们网站的访问者介绍Cookie的性质、功能和使用，并协助您选择计算机设置。通过浏览我们的网站或使用我们不时提供的服务，您接受及同意下述做法。有关我们如何收集和使用您的个人信息以及您可以如何行使您的法定数据主体权利的更多信息，请参阅我们的《隐私政策》。',
    privacy: '隐私政策',
    privacy_content:'中国节能皓信环境顾问集团有限公司及其关联方（“中国节能皓信”或“我们”）尊重阁下的隐私权并保护阁下的个人信息。本隐私政策将说明当阁下访问和使用我们的网站（待補充）（“我们的网站”）时，我们如何收集、共享和使用阁下的个人信息，以及阁下可以如何行使阁下的数据主体权利。通过浏览我们的网站或使用我们的平台及不时提供的服务或其它应用、软件等（合称“我们的服务”），阁下即表示接受及同意下述规则。',
    cancel: '取消',
    ensure: '确定',
    language: '语言',
    subscription: '订阅',
    last_update: '最后更新日期：2023年3月3日',
    show_privacy: '查看完整隐私政策',
    show_cookie: '查看完整Cookie政策',
    wechat_name: '公众号名称',
    wechat_company_name: '中节能皓信CECEPEC',
    agree_cookie: '接受所有Cookies',
    disagree_cookie: '请勿追踪'
}
export default Chinese

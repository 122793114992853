<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted() {
    let url = window.location.href
    let params = this.urlToObj(url)
    let current_language = localStorage.getItem('language') ? localStorage.getItem('language') : ''
    let lang = ''
    if (current_language !== '') {
      lang = current_language
    } else {
      lang = params.language && ['zh', 'tc', 'en'].includes(params.language) ? params.language : ''
    }
    localStorage.setItem('language', lang)
  },
  methods: {
    urlToObj(str){
      let obj = {};
      let arr1 = str.split("?");
      if (arr1.length > 1) {
        let arr2 = arr1[1].split("&");
        for(let i=0 ; i < arr2.length; i++){
          let res = arr2[i].split("=");
          obj[res[0]] = res[1];
        }
      }
      return obj;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

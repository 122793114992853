import { createRouter, useRouter } from "vue-router";
import { createWebHistory } from "vue-router";


export const pageRoutes = [
    {
        path: "/",
        component: () => import("@/view/index.vue"),
        name: 'mediaIndex',
        meta: {
            title: { zh: "多媒体平台", tc: "多媒體平台", en: "Media Platform" }
        },
    }
]

// 解决报错
const originalPush = useRouter.prototype.push;
const originalReplace = useRouter.prototype.replace;
// push
useRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};
// replace
useRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch((err) => err);
};
const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ y: 0 }),
    routes: pageRoutes,
});
router.beforeEach(function (to, from, next) {
    next()
    return
})
export default router;
